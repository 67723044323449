import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';

const root = document.getElementById('root');

if (root) {
  const rootElement = ReactDOM.createRoot(root); 
  rootElement.render(<App />);
} else {
  console.log("Cannot find 'root' DOM element, app won't render...");
}

registerServiceWorker();

