import React from "react";
import { Carousel } from "react-bootstrap";
import styled from "styled-components";
const ImgWrapper = styled.img`
    display: block;
    margin-left: auto;
    margin-right: auto;
    classname: 'd-block w-100';
    max-width: 100%;
    max-height: 90vh;
`;

const PrevIconStyle = <span aria-hidden="true" className="carousel-control-prev-icon" />;
const NextIconStyle = <span aria-hidden="true" className="carousel-control-next-icon" />;

const PhotoCarousel = ({ photos }) => (
    <Carousel
        fade={true}
        interval={null}
        indicators={true}
        prevIcon={PrevIconStyle}
        nextIcon={NextIconStyle}
    >
        {photos.map((photo) => (
            <Carousel.Item key={photo}>
                <ImgWrapper src={photo} alt="Resort" />
            </Carousel.Item>
        ))}
    </Carousel>
);

export default PhotoCarousel;
