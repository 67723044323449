import React, { useState } from "react";
import styled from "styled-components";

import NavBar from "./Components/NavBar/NavBar";
import {
    ResortChoice,
    ParallaxBarSection,
    StrefaCiszySection,
    StokrotkaSection,
    NatureSection,
    NeighbourhoodSection,
    ContactSection
} from "./Sections";

const logo_no_name = require("./Assets/icons/LOGO_beznazwy.png");
const logo_name = require("./Assets/icons/LOGO_nazwa.png");

const AppWrapper = styled.div`
    background: #ececec;
    font: 2vw;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
`;

const App = () => {
    const [chosenResort, setResort] = useState(resorts.StrefaCiszy);

    return (
        <AppWrapper>
            <NavBar logo={logo_no_name} />
            <ParallaxBarSection />
            <ResortChoice chosenResort={chosenResort} setResort={setResort} />
            {chosenResort === resorts.StrefaCiszy ? <StrefaCiszySection /> : <StokrotkaSection />}
            <NeighbourhoodSection />
            <NatureSection />
            <ContactSection logo={logo_name} />
        </AppWrapper>
    );
};

export const resorts = { StrefaCiszy: 0, Stokrotka: 1 };
export default App;
