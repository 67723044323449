import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import PhotoCarousel from "./Components/Carousel/PhotoCarousel.jsx";
import TextSection from "./Components/TextSection";
import ParallaxBar from "./Components/ParallaxBar";
import Map from "./Components/Map/Map.jsx";
import PhotoSelection from "./Components/PhotoSelection";
import text from "./Assets/textData.json";
import { resorts } from "./App";
import {
    strefaCiszyPhotosHorizontal,
    strefaCiszyRecreationPhotosHorizontal,
    stokrotkaPhotosHorizontal,
    surroundingsPhotosHorizontal,
    naturePhotosVertical,
    contactData,
    socialMediaData
} from "./Helpers/AssetsHelper";

const firstParallaxPhoto = require("./Assets/photos/parallaxPhoto.jpg");
const StrefaCiszyPhoto = require("./Assets/photos/strefaCiszy/strefaCiszyChoice.jpg");
const stokrotkaPhoto = require("./Assets/photos/stokrotka/stokrotkaChoice.jpg");

const StrefaCiszy = ({ selectionId, clickHandler }) => (
    <PhotoSelection
        sectionId={resorts.StrefaCiszy}
        selectionId={selectionId}
        photo={StrefaCiszyPhoto}
        text={text.strefaCiszyPhoto.content}
        bottomText={text.resortChoice.strefaCiszyChoiceText}
        clickHandler={clickHandler}
    />
);

const Stokrotka = ({ selectionId, clickHandler }) => (
    <PhotoSelection
        sectionId={resorts.Stokrotka}
        selectionId={selectionId}
        photo={stokrotkaPhoto}
        text={text.stokrotkaPhoto.content}
        bottomText={text.resortChoice.stokrotkaChoiceText}
        clickHandler={clickHandler}
    />
);

const TitleWrapper = styled.h1`
    background-color: #ececec;
    font-size: 5vw;
    text-align: center;
    color: #000000;
    opacity: 0.5;
    font-style: italic;
`;
const image_selection = styled.div`
    border-radius: 20px;
    `;
const surroundings_carousel = styled.div`
    align-items: center;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-content: center;
    flex-direction: row;`;
const Bottom_Logo = styled.div`
    justify-content: flex-start;
    display: flex;
    justify-content: center;
    
`;
const Bottom_Image = styled.img`
    min-width: 30px;
    height: auto;
    width:50%;
    
    `
export const ResortChoice = ({ chosenResort, setResort }) => (
    <div id="osrodek">
        <Container fluid>
            <Row>
                <Col>
                    <TextSection classes="pt-4" title={text.resortChoice.title} text={text.resortChoice.content} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <TitleWrapper>{text.resortChoice.choiceText} </TitleWrapper>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className={`${image_selection}`}>
                        <StrefaCiszy selectionId={chosenResort} clickHandler={setResort} />
                    </div>
                </Col>
                <Col>
                    <Stokrotka selectionId={chosenResort} clickHandler={setResort} />
                </Col>
            </Row>
        </Container>
    </div>
);

export const ParallaxBarSection = () => (
    <ParallaxBar photo={firstParallaxPhoto} title={text.intro.title} text={text.intro.content} />
);

export const StrefaCiszySection = () => (
    <Container fluid>
        <Row>
            <Col>
                <TextSection classes='pt-4' title={text.strefaCiszy.title} text={text.strefaCiszy.content} />
                <PhotoCarousel photos={strefaCiszyPhotosHorizontal} />
            </Col>
        </Row>

        <Row>
            <Col>
                <TextSection classes='pt-4' title={text.recreation.title} text={text.recreation.content} />
            </Col>
        </Row>
        <Row>
            <Col>
                <div className='pt-4'>
                    <PhotoCarousel photos={strefaCiszyRecreationPhotosHorizontal} />
                </div>
            </Col>
        </Row >
    </Container>
);

export const StokrotkaSection = () => (
    <Container fluid>
        <Row>
            <Col>
                <TextSection classes='pt-4' title={text.stokrotka.title} text={text.stokrotka.content} />
            </Col>
        </Row>
        <Row>
            <Col>
                <div className='pt-4'>
                    <PhotoCarousel photos={stokrotkaPhotosHorizontal} />
                </div>
            </Col>
        </Row>
    </Container>
);

export const NeighbourhoodSection = () => (
    <div id="surroundings">
        <Container fluid>
            <Row>
                <Col>
                    <TextSection classes='pt-4' title={text.neighbourhood.title} text={text.neighbourhood.content} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className={`${surroundings_carousel} pt-4 `}>
                        <PhotoCarousel photos={surroundingsPhotosHorizontal} />
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
);

export const NatureSection = () => (
    <div id="okolica">
    <Container fluid>
        <Row>
            <Col>
                {<TextSection classes='pt-4' title={text.nature.title} text={text.nature.content} />}
            </Col>
        </Row>
        <Row>
            <Col>
                <div className={`${surroundings_carousel} pt-4 `}>
                    {<PhotoCarousel photos={naturePhotosVertical} />}
                </div>
            </Col>
        </Row>
    </Container>
    </div>
);

export const ContactSection = ({ logo }) => {
    return (
        <>
            <div id='contact'>
                <Container >
                    <Row>
                        <Col>
                            <div className='pt-4'>
                                <Map />
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container fluid>
                    <Row>
                        {contactData.map((contact) => (
                            <Col>
                                <div key={contact.key} className="contact_item">
                                    <img src={contact.icon} alt={contact.key} />
                                    <span>{contact.text}</span>
                                </div>
                            </Col>
                        ))}
                    </Row>
                    <Row>
                        {socialMediaData.map((media) => (
                            <Col>
                                <div key={media.key} className="media_item">
                                    <a href={media.link}>
                                        <img src={media.icon} alt={media.key} />
                                    </a>
                                    <span>{media.text}</span>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Container>
                <Container fluid>
                    <Row>
                        <Col>
                            <Bottom_Logo>
                                <Bottom_Image src={logo} alt="Logo" />
                            </Bottom_Logo>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};
