import React from "react";
import styled from "styled-components";
import "../index.css";

const ImageContainer = styled.div`
    position: relative;
    display: inline-block;
    width: 100%;
    height: auto;
    border-radius: 20px;
    overflow: hidden;
`;

const TextWrapper = styled.div`
    color: #ffffff;
    font-size: 3vw;
    text-align: justify;
    direction: rtl;
    position: absolute;
    top: 42%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
    text-shadow: 0.15vw 0.1vw rgba(0, 0, 0, 0.8);
    overflow: hidden;
    white-space: nowrap;
    z-index: 2;
    @media (min-width: 992px) {
        font-size: 1.5vw; /* Adjust font size for larger screens */
    }
`;

const BottomTextWrapper = styled.div`
    color: #cecece;
    font-size: 2.25vw;
    position: absolute;
    top: 47%;
    right: 15%;
    opacity: 0.7;
    pointer-events: none;
    white-space: nowrap;
    font-family: 'HolidayFree';
    z-index: 2;
`;

const SelectedImg = styled.img`
    border-radius: 20px;
    display: block;
    width: 100%;
`;

const DeselectedImg = styled.img`
    border-radius: 20px;
    display: block;
    width: 100%;
    filter: grayscale(100%);

    :hover {
        filter: none;
    }
`;

const PhotoSelection = ({ photo, text, sectionId, selectionId, clickHandler, bottomText }) => (
    <ImageContainer>
        {sectionId === selectionId ? (
            <SelectedImg src={photo} alt="Selection" onClick={() => clickHandler(sectionId)} />
        ) : (
            <DeselectedImg src={photo} alt="Selection" onClick={() => clickHandler(sectionId)} />
        )}
        <TextWrapper>{text}</TextWrapper>
        <BottomTextWrapper>{bottomText}</BottomTextWrapper>
    </ImageContainer>
);

export default PhotoSelection;
