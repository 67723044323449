import youtubeIcon from "../Assets/logos/youtube.png";
import facebookIcon from "../Assets/logos/facebook.png";
import bookingIcon from "../Assets/logos/booking.svg";
import tripadvisorIcon from "../Assets/logos/tripadvisor.svg";
import ewczasyIcon from "../Assets/logos/e-wczasy.png";
import eturystaIcon from "../Assets/logos/e-turysta.png";
import mailIcon from "../Assets/icons/mail.png";
import locationIcon from "../Assets/icons/location.png";
import phoneIcon from "../Assets/icons/phone.png";
const getPhotos = (files) => {
    const keys = files.keys();
    return keys.map((key) => files(key));
};

//require.context only accepts hardcoded strings for paths and regexes
const strefaCiszyPhotosHorizontal = getPhotos(
    require.context("../Assets/photos/strefaCiszy/Horizontal", true, /\.(jpe?g|png|gif|svg)$/i)
);

const strefaCiszyRecreationPhotosHorizontal = getPhotos(
    require.context(
        "../Assets/photos/strefaCiszy_recreation/Horizontal",
        true,
        /\.(jpe?g|png|gif|svg)$/i
    )
);

const stokrotkaPhotosHorizontal = getPhotos(
    require.context("../Assets/photos/stokrotka/Horizontal", true, /\.(jpe?g|png|gif|svg)$/i)
);

const naturePhotosVertical = getPhotos(
    require.context("../Assets/photos/okolica/Vertical", true, /\.(jpe?g|png|gif|svg)$/i)
);

const surroundingsPhotosHorizontal = getPhotos(
    require.context("../Assets/photos/otoczenie/horizontal", true, /\.(jpe?g|png|gif|svg)$/i)
);

/* eslint-disable */
const PhotoHeightWidthRatio = (url) => {
    let img = new Image();
    img.src = url;

    return img.naturalHeight / img.naturalWidth;
};

const contactData = [
    {
        key: 'phone',
        icon: phoneIcon,
        text: '(+48) 727 656 676'
    },
    {
        key: 'mail',
        icon: mailIcon,
        text: 'ow.strefaciszy@gmail.com'
    },
    {
        key: 'location',
        icon: locationIcon,
        text: 'Kąty Rybackie ul. Szyprów 3'
    }
];
const socialMediaData = [
    {
        key: 'youtube',
        icon: youtubeIcon,
        link: 'https://www.youtube.com/channel/UCbcOS4cmknlsKoQ97sibeTg/featured'
    },
    {
        key: 'facebook',
        icon: facebookIcon,
        link: 'https://www.facebook.com/jacekiedyta'
    },
    /*{
        key: 'booking',
        icon: bookingIcon,
        link: 'https://www.booking.com/hotel/pl/domki-w-strefie-ciszy.pl.html'
    }*/,
    {
        key: 'tripadvisor',
        icon: tripadvisorIcon,
        link:
            'https://www.tripadvisor.com/Hotel_Review-g1600911-d14914975-Reviews-Domki_W_Strefie_Ciszy-Katy_Rybackie_Pomerania_Province_Northern_Poland.html?m=19905'
    },
    {
        key: 'ewczasy',
        icon: ewczasyIcon,
        link:
            'https://www.e-wczasy.pl/noclegi/katy-rybackie/domki-wypoczynkowe/domki-w-strefie-ciszy-okol-krynica-morska,9001'
    },
    /*{
        key: 'eturysta',
        icon: eturystaIcon,
        link: 'https://e-turysta.pl/domki-w-strefie-ciszy-katy-rybackie-181047.html1'
    }*/
];

const contactIcons = getPhotos(require.context('../Assets/icons', true, /\.(jpe?g|png|gif|svg)$/i));

const socialMediaIcons = getPhotos(
    require.context('../Assets/logos', true, /\.(jpe?g|png|gif|svg)$/i)
);

export {
    getPhotos,
    strefaCiszyPhotosHorizontal,
    strefaCiszyRecreationPhotosHorizontal,
    stokrotkaPhotosHorizontal,
    naturePhotosVertical,
    surroundingsPhotosHorizontal,
    contactData,
    socialMediaData,
    PhotoHeightWidthRatio
};
