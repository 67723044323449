import React from "react";
import styled from "styled-components";
import { Navbar, Nav, Row, Col } from "react-bootstrap";
import ScrollToElement from "../../Helpers/NavHelper";
import { Container } from "react-bootstrap";
import { ColWrapper } from "../StyledBootstrap";
import styles from "./NavBar.module.scss";
import { useEffect } from "react";
const NavTextWrapper = styled.h4`
    color: #000000;
`;
const useScrollToElement = () => {
    const handleScrollToElement = (elementId) => {
        const element = document.getElementById(elementId);
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    };

    return handleScrollToElement;
};
const NavBar = ({ logo }) => {
    const handleScrollToElement = useScrollToElement();
    return (
        <Container fluid>
            <Navbar className={`${styles.navbar_wrapper} ${styles.navbar_wrapper_desktop} row`} bg="light" expand="lg">
                <Col>
                    <Nav className={`${styles.menu_wrapper}`}>
                        <Nav.Link onClick={() => handleScrollToElement("osrodek")}>Ośrodek</Nav.Link>
                        <Nav.Link onClick={() => handleScrollToElement("surroundings")}>Otoczenie</Nav.Link>
                        <Nav.Link onClick={() => handleScrollToElement("okolica")}>Okolica</Nav.Link>
                    </Nav>
                </Col>
                <Col>
                    <Navbar.Brand className={`${styles.logo_wrapper}`}>
                        <img src={logo} className={`${styles.picture_wrapper}`} alt="Logo" />
                    </Navbar.Brand>
                </Col>
                <Col>
                    <Nav className={`${styles.contact_wrapper}`}>
                        <Nav.Link onClick={() => handleScrollToElement("contact")}>Kontakt</Nav.Link>
                    </Nav>
                </Col>
            </Navbar>

            <Navbar className={`${styles.navbar_wrapper} ${styles.navbar_wrapper_mobile} row`} bg="light" expand="lg">
                <Col xs={6}>
                    <Navbar.Brand className={`${styles.logo_wrapper}`}>
                        <img src={logo} className={`${styles.picture_wrapper}`} alt="Logo" />
                    </Navbar.Brand>
                </Col>
                <Col xs={6}>
                    <div className={`${styles.button_wrapper}`}>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    </div>
                </Col>
                <Col xs={12}>
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className={`${styles.menu_wrapper}`}>
                            <Nav.Link onClick={() => handleScrollToElement("osrodek")}>Ośrodek</Nav.Link>
                            <Nav.Link onClick={() => handleScrollToElement("surroundings")}>Otoczenie</Nav.Link>
                            <Nav.Link onClick={() => handleScrollToElement("okolica")}>Okolica</Nav.Link>
                        </Nav>
                        <Nav className={`${styles.contact_wrapper}`}>
                            <Nav.Link onClick={() => handleScrollToElement("contact")}>Kontakt</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Col>
            </Navbar>
        </Container >
    );
};
export default NavBar;

