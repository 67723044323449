import React from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import styled from "styled-components";

const mapConfig = {
    lat: 54.329985,
    lng: 19.214533,
    zoom: 15
};

const shortid = require("shortid");
const markers = [
    {
        id: shortid.generate(),
        text: "Domek Stokrotka.",
        lat: 54.334957,
        lng: 19.217427
    },
    {
        id: shortid.generate(),
        text: "OW Strefa Ciszy.",
        lat: 54.329718,
        lng: 19.214533
    }
];

const MapWrapper = styled.div`
    width: 100%;
    height: 10%;
`;

const Map = () => (
    <MapWrapper>
        <MapContainer
            center={[mapConfig.lat, mapConfig.lng]}
            zoom={mapConfig.zoom}
            scrollWheelZoom={false}
            style={{ height: "80vh", width: "100%" }}
        >
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            />
            {markers.map((marker) => (
                <Marker position={[marker.lat, marker.lng]} key={marker.id}>
                    <Popup>{marker.text}</Popup>
                </Marker>
            ))}
        </MapContainer>
    </MapWrapper>
);

export default Map;
